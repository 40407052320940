<script lang="ts" context="module">
  type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

  type TextVariants = {
    display: ['152', '128', '104', '68', '52'];
    heading: ['68', '60', '48', '32', '24'];
    body: ['32', '24', '20', '16'];
    eyebrow: ['18', '16', '14'];
    mono: ['18', '16'];
  };

  type TextVariant = keyof {
    [K in keyof TextVariants as `${K}-${TextVariants[K][number]}`]: never;
  };
</script>

<script lang="ts">
  import type { Background } from '$lib/contentful/models/background';
  import { twMerge } from 'tailwind-merge';

  type $$Props = {
    as?: TextElement;
    variant?: TextVariant;
    class?: string;
    entityId?: string;
    fieldId?: string;
    background?: Background;
    id?: string;
  };

  export let as: TextElement = 'p';
  export let background: Background | undefined = undefined;
  export let entityId: string | undefined = undefined;
  export let fieldId: string | undefined = undefined;
  export let id: string | undefined = undefined;

  let className = '';
  export { className as class };

  let textVariant: TextVariant | undefined = undefined;
  export { textVariant as variant };

  $: [variant, size] = textVariant ? textVariant.split('-') : ['', ''];

  const textColors: Record<typeof variant, Record<Background, string>> = {
    display: {
      mist: 'text-off-white',
      abstract: 'text-off-white',
      none: 'text-off-white',
      black: 'text-off-white',
      stars: 'text-off-white',
      grid: 'text-off-white',
      tardigrade: 'text-off-white',
      'purple-ultraviolet-gradient': 'text-off-white',
      'purple-ultraviolet-gradient-stars-grid': 'text-off-white',
      'green-gradient': 'text-space-black',
      'pink-gradient': 'text-off-white',
      'black-ultraviolet-gradient-grid': 'text-off-white',
      'black-blue-gradient-stars': 'text-off-white',
      'black-blue-gradient-stars-grid': 'text-off-white',
      'customer-hype-rainbow': 'text-off-white',
      'windows-95': 'text-off-white',
      'deep-blue': 'text-off-white',
      cloud: 'text-off-white',
      oss: 'text-off-white',
      moonscape: 'text-off-white',
      'abstract-grid': 'text-off-white',
      'solar-system': 'text-off-white',
      'deepest-blue': 'text-off-white',
    },
    heading: {
      mist: 'text-space-black',
      abstract: 'text-off-white',
      none: 'text-off-white',
      black: 'text-off-white',
      stars: 'text-off-white',
      grid: 'text-off-white',
      tardigrade: 'text-off-white',
      'purple-ultraviolet-gradient': 'text-off-white',
      'purple-ultraviolet-gradient-stars-grid': 'text-off-white',
      'green-gradient': 'text-space-black',
      'pink-gradient': 'text-off-white',
      'black-ultraviolet-gradient-grid': 'text-off-white',
      'black-blue-gradient-stars': 'text-off-white',
      'black-blue-gradient-stars-grid': 'text-off-white',
      'customer-hype-rainbow': 'text-off-white',
      'windows-95': 'text-off-white',
      'deep-blue': 'text-off-white',
      cloud: 'text-off-white',
      oss: 'text-off-white',
      moonscape: 'text-off-white',
      'abstract-grid': 'text-off-white',
      'solar-system': 'text-off-white',
      'deepest-blue': 'text-off-white',
    },
    body: {
      mist: 'text-space-black',
      abstract: 'text-off-white',
      none: 'text-off-white',
      black: 'text-off-white',
      stars: 'text-off-white',
      grid: 'text-off-white',
      tardigrade: 'text-off-white',
      'purple-ultraviolet-gradient': 'text-off-white',
      'purple-ultraviolet-gradient-stars-grid': 'text-off-white',
      'green-gradient': 'text-space-black',
      'pink-gradient': 'text-off-white',
      'black-ultraviolet-gradient-grid': 'text-off-white',
      'black-blue-gradient-stars': 'text-off-white',
      'black-blue-gradient-stars-grid': 'text-off-white',
      'customer-hype-rainbow': 'text-off-white',
      'windows-95': 'text-off-white',
      'deep-blue': 'text-off-white',
      cloud: 'text-off-white',
      oss: 'text-off-white',
      moonscape: 'text-off-white',
      'abstract-grid': 'text-off-white',
      'solar-system': 'text-off-white',
      'deepest-blue': 'text-off-white',
    },
    eyebrow: {
      mist: 'text-space-black',
      abstract: 'text-mint',
      none: 'text-mint',
      black: 'text-mint',
      stars: 'text-mint',
      grid: 'text-mint',
      tardigrade: 'text-mint',
      'purple-ultraviolet-gradient': 'text-mint',
      'purple-ultraviolet-gradient-stars-grid': 'text-mint',
      'green-gradient': 'text-space-black',
      'pink-gradient': 'text-off-white',
      'black-ultraviolet-gradient-grid': 'text-mint',
      'black-blue-gradient-stars': 'text-mint',
      'black-blue-gradient-stars-grid': 'text-mint',
      'customer-hype-rainbow': 'text-mint',
      'windows-95': 'text-mint',
      'deep-blue': 'text-mint',
      cloud: 'text-off-white',
      oss: 'text-off-white',
      moonscape: 'text-mint',
      'abstract-grid': 'text-off-white',
      'solar-system': 'text-mint',
      'deepest-blue': 'text-mint',
    },
    mono: {
      mist: 'text-space-black',
      abstract: 'text-off-white',
      none: 'text-off-white',
      black: 'text-off-white',
      stars: 'text-off-white',
      grid: 'text-off-white',
      tardigrade: 'text-off-white',
      'purple-ultraviolet-gradient': 'text-off-white',
      'purple-ultraviolet-gradient-stars-grid': 'text-off-white',
      'green-gradient': 'text-space-black',
      'pink-gradient': 'text-space-black',
      'black-ultraviolet-gradient-grid': 'text-off-white',
      'black-blue-gradient-stars': 'text-off-white',
      'black-blue-gradient-stars-grid': 'text-off-white',
      'customer-hype-rainbow': 'text-off-white',
      'windows-95': 'text-off-white',
      'deep-blue': 'text-off-white',
      cloud: 'text-off-white',
      oss: 'text-off-white',
      moonscape: 'text-off-white',
      'abstract-grid': 'text-off-white',
      'solar-system': 'text-off-white',
      'deepest-blue': 'text-off-white',
    },
  };

  $: textColorClassName =
    variant && background ? textColors[variant][background] : '';
</script>

<svelte:element
  this={as}
  {id}
  data-variant={variant}
  data-size={size}
  class={twMerge(
    'text',
    `s${size}`,
    background,
    variant,
    textColorClassName,
    className,
  )}
  data-contentful-entry-id={entityId}
  data-contentful-field-id={fieldId}
>
  <slot />
</svelte:element>

<style lang="postcss">
  .text {
    &.s152 {
      @apply max-lg:text-[76px] lg:text-[114px] lg:tracking-[-2px] xl:text-[152px];
    }
    &.s128 {
      @apply max-lg:text-[64px] max-md:text-[48px] lg:text-[96px] lg:tracking-[-2px] xl:text-[128px];
    }
    &.s104 {
      @apply max-lg:text-[48px] lg:text-[78px] lg:tracking-[-2px] xl:text-[104px];
    }
    &.s86 {
      @apply max-lg:text-[48px] lg:text-[72px] lg:tracking-[-2px] xl:text-[86px];
    }
    &.s68 {
      @apply max-lg:text-[52px] max-md:text-[44px] lg:text-[60px] lg:tracking-[-2px] xl:text-[68px];
    }
    &.s60 {
      @apply max-md:text-[36px] md:text-[44px] lg:text-[52px] xl:text-[60px];
    }
    &.s52 {
      @apply text-[52px];
    }
    &.s48 {
      @apply max-xl:text-[32px] xl:text-[48px];
    }
    &.s32 {
      @apply max-xl:text-[24px] xl:text-[32px];
    }
    &.s24 {
      @apply max-xl:text-[18px] xl:text-[24px];
    }
    &.s20 {
      @apply max-xl:text-[18px] xl:text-[20px];
    }
    &.s18 {
      @apply max-md:text-[12px] md:text-[18px];
    }
    &.s16 {
      @apply max-md:text-[12px] md:text-[14px] xl:text-[16px];
    }
    &.s14 {
      @apply max-md:text-[12px] md:text-[12px] xl:text-[14px];
    }
  }

  .display {
    @apply font-sans font-light leading-[110%] max-md:tracking-normal md:tracking-[-2px];
  }

  .heading {
    @apply font-sans leading-[120%] max-lg:tracking-normal;
  }

  .body {
    @apply font-sans font-light leading-[140%];
  }

  .eyebrow,
  .mono {
    @apply font-mono font-medium tracking-tight;

    &.s16,
    &.s14 {
      @apply uppercase;
    }
  }
</style>
